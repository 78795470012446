import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));
// Pages 
const Home = React.lazy(() => import("./components/pages/Home"));
const Animation = React.lazy(() => import("./components/pages/Animation"));
const Widgets = React.lazy(() => import("./components/pages/Widgets"));
// apps
const Chat = React.lazy(() => import("./components/pages/apps/Chat"));
const Email = React.lazy(() => import("./components/pages/apps/Email"));
const Todolist = React.lazy(() => import("./components/pages/apps/Todolist"));
// charts
const Chartjs = React.lazy(() => import("./components/pages/charts/Chartjs"));
const Googlecharts = React.lazy(() => import("./components/pages/charts/Googlecharts"));
// dashboard
const Clientmanagement = React.lazy(() => import("./components/pages/dashboard/Clientmanagement"));
const Projectmanagement = React.lazy(() => import("./components/pages/dashboard/Projectmanagement"));
const Socialmedia = React.lazy(() => import("./components/pages/dashboard/Socialmedia"));
const Webanalytics = React.lazy(() => import("./components/pages/dashboard/Webanalytics"));
// form
const Formelements = React.lazy(() => import("./components/pages/form/Formelements"));
const Formlayouts = React.lazy(() => import("./components/pages/form/Formlayouts"));
const Formvalidation = React.lazy(() => import("./components/pages/form/Formvalidation"));
const Formwizard = React.lazy(() => import("./components/pages/form/Formwizard"));
// icons
const Flaticons = React.lazy(() => import("./components/pages/icons/Flaticons"));
const Fontawesome = React.lazy(() => import("./components/pages/icons/Fontawesome"));
const Materialize = React.lazy(() => import("./components/pages/icons/Materialize"));
// maps
const Googlemaps = React.lazy(() => import("./components/pages/maps/Googlemaps"));
const Vectormaps = React.lazy(() => import("./components/pages/maps/Vectormaps"));
// popups
const Sweetalerts = React.lazy(() => import("./components/pages/popups/Sweetalerts"));
const Toast = React.lazy(() => import("./components/pages/popups/Toast"));
// prebuilt-pages
const Comingsoon = React.lazy(() => import("./components/pages/prebuilt-pages/Comingsoon"));
const Defaultlogin = React.lazy(() => import("./components/pages/prebuilt-pages/Defaultlogin"));
const Defaultregister = React.lazy(() => import("./components/pages/prebuilt-pages/Defaultregister"));
const Error = React.lazy(() => import("./components/pages/prebuilt-pages/Error"));
const Faq = React.lazy(() => import("./components/pages/prebuilt-pages/Faq"));
const Invoice = React.lazy(() => import("./components/pages/prebuilt-pages/Invoice"));
const Lockscreen = React.lazy(() => import("./components/pages/prebuilt-pages/Lockscreen"));
const Modallogin = React.lazy(() => import("./components/pages/prebuilt-pages/Modallogin"));
const Modalregister = React.lazy(() => import("./components/pages/prebuilt-pages/Modalregister"));
const Portfolio = React.lazy(() => import("./components/pages/prebuilt-pages/Portfolio"));
const Userprofile = React.lazy(() => import("./components/pages/prebuilt-pages/Userprofile"));
// tables
const Basictables = React.lazy(() => import("./components/pages/tables/Basictables"));
const Datatables = React.lazy(() => import("./components/pages/tables/Datatables"));
// uiadvance
const Cropper = React.lazy(() => import("./components/pages/uiadvance/Cropper"));
const Draggables = React.lazy(() => import("./components/pages/uiadvance/Draggables"));
const Modals = React.lazy(() => import("./components/pages/uiadvance/Modals"));
const Rangeslider = React.lazy(() => import("./components/pages/uiadvance/Rangeslider"));
const Rating = React.lazy(() => import("./components/pages/uiadvance/Rating"));
const Sliders = React.lazy(() => import("./components/pages/uiadvance/Sliders"));
const Tour = React.lazy(() => import("./components/pages/uiadvance/Tour"));
// uibasic
const Accordions = React.lazy(() => import("./components/pages/uibasic/Accordions"));
const Alerts = React.lazy(() => import("./components/pages/uibasic/Alerts"));
const Badges = React.lazy(() => import("./components/pages/uibasic/Badges"));
const Breadcrumbs = React.lazy(() => import("./components/pages/uibasic/Breadcrumbs"));
const Buttons = React.lazy(() => import("./components/pages/uibasic/Buttons"));
const Cards = React.lazy(() => import("./components/pages/uibasic/Cards"));
const Pagination = React.lazy(() => import("./components/pages/uibasic/Pagination"));
const Preloaders = React.lazy(() => import("./components/pages/uibasic/Preloaders"));
const Progressbars = React.lazy(() => import("./components/pages/uibasic/Progressbars"));
const Tabs = React.lazy(() => import("./components/pages/uibasic/Tabs"));
const Typography = React.lazy(() => import("./components/pages/uibasic/Typography"));

function App() {
  return (
    // <Router basename={'/themes/themeforest/react/mystic'}>
    <Router basename={'/'}>
      <Suspense fallback={<div></div>}>
        <Preloader />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/animation" component={Animation} />
          <Route path="/widgets" component={Widgets} />
          {/* apps */}
          <Route path="/apps/chat" component={Chat} />
          <Route path="/apps/email" component={Email} />
          <Route path="/apps/to-do-list" component={Todolist} />
          {/* charts */}
          <Route path="/charts/chartjs" component={Chartjs} />
          <Route path="/charts/google-charts" component={Googlecharts} />
          {/* dashboard */}
          <Route path="/dashboard/client-management" component={Clientmanagement} />
          <Route path="/dashboard/project-management" component={Projectmanagement} />
          <Route path="/dashboard/social-media" component={Socialmedia} />
          <Route path="/dashboard/web-analytics" component={Webanalytics} />
          {/* form */}
          <Route path="/form/form-elements" component={Formelements} />
          <Route path="/form/form-layout" component={Formlayouts} />
          <Route path="/form/form-validation" component={Formvalidation} />
          <Route path="/form/form-wizard" component={Formwizard} />
          {/* icons */}
          <Route path="/icons/flaticons" component={Flaticons} />
          <Route path="/icons/fontawesome" component={Fontawesome} />
          <Route path="/icons/materialize" component={Materialize} />
          {/* maps */}
          <Route path="/maps/google-maps" component={Googlemaps} />
          <Route path="/maps/vector-maps" component={Vectormaps} />
          {/* popups */}
          <Route path="/popups/sweet-alerts" component={Sweetalerts} />
          <Route path="/popups/toast" component={Toast} />
          {/* prebuilt-pages */}
          <Route path="/prebuilt-pages/coming-soon" component={Comingsoon} />
          <Route path="/prebuilt-pages/default-login" component={Defaultlogin} />
          <Route path="/prebuilt-pages/default-register" component={Defaultregister} />
          <Route path="/prebuilt-pages/error" component={Error} />
          <Route path="/prebuilt-pages/faq" component={Faq} />
          <Route path="/prebuilt-pages/invoice" component={Invoice} />
          <Route path="/prebuilt-pages/lock-screen" component={Lockscreen} />
          <Route path="/prebuilt-pages/modal-login" component={Modallogin} />
          <Route path="/prebuilt-pages/modal-register" component={Modalregister} />
          <Route path="/prebuilt-pages/portfolio" component={Portfolio} />
          <Route path="/prebuilt-pages/user-profile" component={Userprofile} />
          {/* tables */}
          <Route path="/tables/basic-tables" component={Basictables} />
          <Route path="/tables/data-tables" component={Datatables} />
          {/* uiadvance */}
          <Route path="/ui-advanced/cropper" component={Cropper} />
          <Route path="/ui-advanced/draggables" component={Draggables} />
          <Route path="/ui-advanced/modals" component={Modals} />
          <Route path="/ui-advanced/range-slider" component={Rangeslider} />
          <Route path="/ui-advanced/rating" component={Rating} />
          <Route path="/ui-advanced/sliders" component={Sliders} />
          <Route path="/ui-advanced/tour" component={Tour} />
          {/* uibasic */}
          <Route path="/ui-basic/accordions" component={Accordions} />
          <Route path="/ui-basic/alerts" component={Alerts} />
          <Route path="/ui-basic/badges" component={Badges} />
          <Route path="/ui-basic/breadcrumbs" component={Breadcrumbs} />
          <Route path="/ui-basic/buttons" component={Buttons} />
          <Route path="/ui-basic/cards" component={Cards} />
          <Route path="/ui-basic/pagination" component={Pagination} />
          <Route path="/ui-basic/preloaders" component={Preloaders} />
          <Route path="/ui-basic/progress-bars" component={Progressbars} />
          <Route path="/ui-basic/tabs" component={Tabs} />
          <Route path="/ui-basic/typography" component={Typography} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
